.App {
  text-align: center;
}

.footimg {
  width: 50%;
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

body {
  animation: appear 1.5s ease-in forwards;
}

body::-webkit-scrollbar {
  width: 2.5px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid rgb(40, 0, 140);
  /* creates padding around scroll thumb */
}

.adventure {
  height: 20rem !important;
}



.spider {
  position: fixed;
  top: -90%;
  width: 50%;
  height: auto;
  z-index: 21989283;
  cursor: url('pointer.png'), auto;

}

.spider:hover {
  transition: linear 0.1s;
  top: -25%;
}

.topHeader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: 99999 !important;
  min-height: 5vh;
  padding: 0.5rem;
  padding-inline: 1.5rem;
  padding-right: 1.8rem;
  box-sizing: border-box;
  border-radius: 3px;

  border-bottom-left-radius: 15px;

  border-bottom-right-radius: 15px;
  /* From https://css.glass */
  background: rgba(6, 22, 86, 0.19);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 19rem;

}

.topHeader:hover {
  transition: linear 0.05s;
  top: -5px;
  cursor: url('pointer.png'), auto;
}

.diagonal {
  /* From https://css.glass */
  position: absolute;
  transform: rotate(-5deg);
  background: rgba(0, 0, 0, 0.23);
  height: 110%;
  width: 200%;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  position: relative;
  background: white;
  /* Add a subtle shadow */
  overflow-x: hidden;
  gap: 2.5rem;
  overflow-y: hidden;
  background-color: #e8d1e3;
  background-position: center;

}

.section {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  min-height: 30vh;
  align-items: center;
  box-sizing: border-box;
  opacity: 0 !important;
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.section.visible {
  opacity: 1 !important;
}


.marquee-cont {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  height: 6vh !important;
  position: relative;
  z-index: 50;
  width: 120vw;
  background-color: #d56669;
  margin-top: 1.5rem;
  transform: rotate(-3deg);
}



.marquee-contmemes {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  height: 19rem !important;
  position: relative;
  z-index: 50;
  width: 120vw;
}

.marqimg {
  height: 20rem;
  width: auto;
  object-fit: cover;
}




.joinbg {
  background-size: cover;
}

.marquee-cont2 {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  z-index: 50;
  background-color: transparent;
  width: 100vw;
  height: 50vh;
}

.track {
  position: absolute;
  gap: 1rem;
  display: flex;
  white-space: nowrap;
}



.track>h1 {
  font-size: 2.8rem;
  text-transform: uppercase;
  color: white !important;
  color: #fff;
  /* Text color */
}

.land {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;

  padding-top: 2rem;
  background-color: transparent;
}

.land2 {
  display: flex;
  gap: 1rem;
  min-height: 100vh;
  width: 100%;
  flex-direction: row;
}





.left {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  z-index: 2;
  flex-direction: column;
}


.right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 35rem;
  flex-direction: column;
}


.lbody {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  z-index: 100000 !important;
  width: 35rem;
}

.dex {
  height: 25px;
  width: 25px;
}

.dex2 {
  height: 40px;
  width: 40px;
}

.dex2:hover {

  animation: shakeRotate 0.5s ease-in-out;
}

.home {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;

  cursor: url('normal.png'), auto;
}

.buttonsH {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  z-index: 99999 !important;
}


.foot {
  justify-content: flex-end;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  position: relative;

  font-weight: bold;
  z-index: 5000 !important;
  background-size: cover;
  background-repeat: no-repeat;
  color: darkblue !important;
  height: 90vh;
  text-align: center;
  margin-top: 4rem;
}

.foot p {
  color: white !important;
  text-align: center;
  word-wrap: break-word;
  word-break: normal;
  font-size: 1.5rem !important;
}

.foot h1 {
  color: #e4c866 !important;
  font-size: 2rem;
}

.footer {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.footerAbout {
  font-size: x-large !important;
  width: 85%;

  text-shadow: 2px 2px black;
  text-align: justify;
  word-break: break-word;
}

.footer h1 {
  font-size: 1.4rem !important;
}

.bg {
  width: 100%;
  object-fit: contain;
}

.footerimg {
  height: 30rem;
}

.h1 {
  text-shadow: 2px 2px black;
}

.radca {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 10px;
  padding-inline: 1.2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #d56669;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  border: 1px solid rgba(255, 255, 255, 0.22);
  width: auto;
  z-index: 9999999 !important;
}


.radca p {
  color: white !important;
  font-weight: bold;
  word-break: break-all;
  white-space: normal;
}


.radabout h1 {
  color: black !important;
  padding-inline: 2rem;
}

.calabel {
  font-size: 1.5rem !important;
  color: black
}


.ca {
  font-size: 1rem !important;
  text-align: center;
  position: relative;
  transition: 0.05s linear !important;
  font-family: "Lilita One", sans-serif;
  z-index: 1000;
  color: black !important
}


.leftyrighty {
  display: flex;
  min-height: 100vh !important;
  flex-direction: row;
  min-width: 100%;
}

.leftyrighty3 {
  flex-wrap: wrap !important;
}

.leftyrighty2 {
  flex-wrap: wrap-reverse !important;
}


.cardboard {
  width: 19rem;
  height: 21rem;
  display: flex;
  padding: 1rem;
  box-sizing: border-box;

  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  background-image: url('./landb.png');
  background-size: cover;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;
}

.cardboard img {
  object-fit: cover;
  height: 15rem;
  max-width: 17rem;
  cursor: url('pointer.png'), auto;
}

.cardboard:hover {

  animation: shakeRotate 0.5s ease-in-out;
}

.cardboard h1 {
  font-size: 1.3rem;
}

.spacetop {
  margin-top: 5rem;
}

.lefty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  min-height: 100%;
  padding: 2rem;
  min-width: 21rem;
  width: 50%;
  gap: 1.5rem;
}

.lefty div {
  font-size: 4rem;
  width: 80%;
}

.lefty h1 {

  text-shadow: 2px 2px black !important;
  font-size: 1.3rem !important;
  word-wrap: break-word !important;
  word-break: normal !important;
}



.righty {
  flex: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}


span {
  font-family: "Chewy", serif;
}

.h1 {

  font-family: "Chewy", serif;
  color: white;

}

.sText {
  color: #ea8b25 !important;
  text-shadow: 2px 2px black;
}

.sText2 {
  color: #6e4422 !important;
}

.ca:hover {
  cursor: url('pointer.png'), auto;
}

.radca:hover {
  cursor: url('pointer.png'), auto;

  background-color: #ea8b25;
}

.aboutcontainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 18px;
  width: 80vw;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  gap: 1.5rem;
}



.snow {
  background-size: cover;
  background-repeat: no-repeat;
}


.snowTop {
  background-size: cover;
  background-repeat: no-repeat;
}


.aboutcontainer:hover {
  cursor: url('pointer.png'), auto;
  transform: scale(1.05);
}

.aboutcontainer h1 {
  font-size: 3rem !important;
  width: 50%;
  text-align: left !important;

}


.aboutcontainer p {
  font-size: 1.2rem !important;
  width: 90%;
  word-break: normal !important;
  word-wrap: break-word !important;
  text-align: justify;
  line-height: 2rem;
  color: white !important;
}

.aboutcontainer img {
  width: 22rem;
}

.radca:hover .ca {
  color: white !important
}


.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}


.about p {
  font-size: 1.3rem !important;
  font-weight: bold;
  width: 30rem;
}


.logs {
  cursor: url('pointer.png'), auto;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;

  /* From https://css.glass */
}

.logs img {
  height: 5rem;
  width: 5rem;
}

@keyframes inscribe {

  0% {
    opacity: 0;
  }

  60% {
    opacity: 0
  }

  80% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }

}

.logs p {
  color: black !important;
}

.logs:hover {
  transition: 0.2s ease;

  animation: shakeRotate 0.5s ease-in-out;
}

.logs:hover>.loginfo {

  display: flex !important;
}

.txt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logotxt {
  width: 18rem;
  align-self: center;
}


.logo2 {
  width: 70%;
  cursor: url('pointer.png'), auto;
  margin-bottom: -2rem;
}

.pulse {

  animation: pulse 3s ease-in-out infinite alternate;
}


.bullit {

  animation: shakeRotate 3s ease-in-out infinite;
}

.rotate {
  animation: rotateLeftRight 5s ease-in-out infinite;
}

@keyframes rotateLeftRight {
  0% {
    transform: rotateY(0deg);
    /* Starting point */
  }

  25% {
    transform: rotateY(-90deg);
    /* Rotate to the left */
  }

  50% {
    transform: rotateY(0deg);
    /* Back to the center */
  }

  75% {
    transform: rotateY(50deg);
    /* Rotate to the right */
  }

  100% {
    transform: rotateY(0deg);
    /* Return to the center */
  }
}

.pinkbag {
  position: fixed;
  bottom: 0;
  left: 0rem;
  width: 30%;
  z-index: 500000000000 !important;
  pointer-events: none;
  animation: upup 5s ease-in-out infinite alternate;

}



@keyframes upup {
  0% {
    bottom: -100%;
  }


  100% {
    bottom: -1rem;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
    /* Initial size */
    opacity: 1;
    /* Fully visible */
  }

  50% {
    transform: scale(1.15);
    /* Scaled up by 20% */
    opacity: 1;
    /* Slightly transparent */
  }

  100% {
    transform: scale(1);
    /* Back to original size */
    opacity: 1;
    /* Fully visible */
  }
}


.logo:hover {

  animation: shakeRotate 0.5s ease-in-out;
}

.logo2:hover {
  filter: brightness(1.05)
}

.shakeRotate:hover {

  animation: shakeRotate 0.5s ease-in-out;

}

.hero {
  background-repeat: no-repeat;
  background-size: cover !important;
}

@keyframes shakeRotate {
  0% {
    transform: translate(1px, 0) rotate(0deg);
  }

  25% {
    transform: translate(-1px, 0) rotate(-5deg);
  }

  50% {
    transform: translate(1px, 0) rotate(5deg);
  }

  75% {
    transform: translate(-1px, 0) rotate(-5deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes shakeRotate2 {
  0% {
    transform: translate(1px, 0) rotate(0deg) scaleX(-1);
  }

  25% {
    transform: translate(-1px, 0) rotate(-5deg) scaleX(-1);
  }

  50% {
    transform: translate(1px, 0) rotate(5deg) scaleX(-1);
  }

  75% {
    transform: translate(-1px, 0) rotate(-5deg) scaleX(-1);
  }

  100% {
    transform: translate(0, 0) rotate(0deg) scaleX(-1);
  }
}

.ltextcont {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

}

.ltextcont2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

}

.sHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
}


.centercolumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex: 1;
}


.rowrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.rowrownorm {
  flex-wrap: wrap;
}

.rowrowre {
  flex-wrap: wrap-reverse;
}


.rowleft {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 19rem;
  height: 18rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  border: 1px solid rgba(255, 255, 255, 0.22);
  cursor: url('pointer.png'), auto;

  background: #9d5a71;
}

.rowLeft:hover {

  border: 1px solid #e4c86620 !important;
}

.rowRight:hover {
  transform: scale(1.03) !important;
}

.rmapp {
  color: white !important;
  word-break: normal !important;
  word-wrap: break-word !important;
  text-align: center;
}

.rowRight {
  width: 19rem;
  height: 18rem;
  border-radius: 16px;
}

.colcol {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: justify;
  flex: 2;
  justify-content: center;
  align-items: start;
  min-height: 18rem;
  object-fit: cover;
  position: relative;
}

.colcol h1 {
  font-size: 2rem !important;
}


.colcol p {
  color: white;

  font-size: 1.4rem !important;
}

.hbg {
  position: absolute;
  z-index: 1;
  object-fit: cover;
  width: 80%;
}

.blur {

  filter: blur(4px);
}

.landscape {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  padding: 1rem;
  width: 70%;
  position: relative;

  background: #9d5a71;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.stickerLeft {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -3rem;
  left: -3rem;
  z-index: 99999;
}

.stickerLeft:hover {
  cursor: url('pointer.png'), auto;
  animation: shakeRotate 0.5s ease-in-out;
}

.stickerRight:hover {
  cursor: url('pointer.png'), auto;
  animation: shakeRotate2 0.5s ease-in-out;
}

.stickerRight {
  width: 10rem;
  height: 10rem;
  position: absolute;
  bottom: -3rem;
  right: -3rem;
  transform: scaleX(-1);

  z-index: 99999;
}


.landscape:hover {
  cursor: url('pointer.png'), auto;
  background-image: url('./sun.png');
  background-size: cover;
}

.landscape:hover p,
.landscape:hover h1,
.landscape:hover * {
  color: white !important;
}

.centercolumnimg {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 50000 !important;
}

.centercolumnimg h1 {
  text-align: center;
  font-size: 1.8rem !important;
}

.centercolumnimg>* {
  width: 50%;
  height: auto;
  object-fit: contain;
}


.ltextcont h1,
.landscape h1 {
  font-size: 7rem;
  color: #ea8b25 !important;
  text-shadow: 2px 2px rgb(0, 0, 0);
  user-select: none;
}



.ltextcont2 h1 {
  font-size: 6rem;
  color: white !important;
  user-select: none;
  animation: appear 3s ease-in forwards;
  text-align: center;
}


.btnLine {
  padding: 0.5rem;
  font-weight: bold;
  padding-inline: 0.8rem;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: url('pointer.png'), auto;
  padding-inline: 1rem;
  color: white !important;
  width: fit-content;
  z-index: 1000 !important;
  display: flex;
  gap: 4rem;
  font-size: 2rem !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 550;
}

.btnLine a {
  color: #6d4322 !important;
}

.btnLine:hover {
  animation: shakeRotate 0.5s ease-in-out;
}

.btnBig {
  padding-block: 1.2rem;
  padding-top: 0.9rem;
  font-weight: bold;
  padding-inline: 0.8rem;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 16px;
  cursor: url('pointer.png'), auto;
  padding-inline: 1rem;
  color: #d56669 !important;
  width: fit-content;
  z-index: 1000 !important;
  display: flex;
  gap: 4rem;
  font-size: 2rem !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 550;
  background: #d56669;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.8px);
  -webkit-backdrop-filter: blur(4.8px);
  border: 1px solid rgba(255, 255, 255, 0.22);
}



.btnBig:hover {

  animation: shakeRotate 0.5s ease-in-out;
}

.btnBig:hover a {

  color: white !important;
}

.ldiv1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.vidcont {
  width: 80%;
  padding: 1.5rem;
}

.vidcont iframe {
  widtH: 100%;
  height: 75vh;
}

.buttonsH {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  user-select: all !important;
}



.buttonsH2 {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  user-select: all !important;
}


h1 {
  color: white;
  font-size: 3.5rem;
}

p {
  font-size: 1rem !important;
}

.lion {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100%;
  object-fit: cover !important;
}

.footimgabs {
  position: absolute;
  bottom: 2rem;
  width: 70%;

}

.test2 {
  /* From https://css.glass */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.testbg {

  background-image: url('./landb.png');
  background-size: cover;
  background-position: center;
}

.cardcontainer {
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.card {
  width: 19rem;
  background-color: #1b1d22 !important;
  display: flex;
  height: 18rem;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  gap: 1rem;
  overflow: hidden;
  box-shadow: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.cardimage {
  object-fit: cover;
  height: 12rem;
  border-radius: 8px;
}

.cardcontent {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.cardcontent div {
  color: white !important;
  font-size: 1.2rem !important;
}

.aboutcontainerbg {
  background-size: cover;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background-color: #1b1d22;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-image: url('./lgt.PNG');
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 50vh;
}

.aboutcontainerbg>* {
  width: 90% !important;
}

.yo {
  line-height: 1.5rem;
}

.texture {
  background-size: contain;
  background-image: url('./texture.png');
}

.extramargin {
  margin-top: 6rem;
}


@media only screen and (max-width: 970px) {




  .cardcontainer {
    width: 97%;
  }


  .pinkbag {
    width: 60%;
  }

  .marquee-cont {
    margin-top: 0;
  }

  .land {
    padding-top: 0;
  }

  .hbg {
    width: auto;
    height: 70%;
    object-fit: cover;
    bottom: 0;
  }

  .spider {
    width: 100%;
  }

  .container {
    background-size: 150%;
  }

  .lion {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  .aboutcontainer p,
  .aboutcontainer h1 {
    font-size: 2rem !important;
    width: 80%;
    word-break: normal !important;
    word-wrap: break-word !important;
    text-align: justify;
    line-height: 2rem;
  }

  .aboutcontainer {
    width: 90vw;
  }

  .logo {
    width: 60%;
  }

  .logo2 {
    width: 95%;
  }

  .header {

    margin-block: 0.5rem;
    margin-top: 1rem;
    margin-inline: 0.5rem;
    margin-bottom: 3rem;
    padding-inline: 0.5rem;
    gap: 0.5rem;
  }


  .land {
    gap: 2rem !important;
  }

  .ltextcont h1,
  .landscape h1 {
    font-size: 5rem;
  }

  .ltextcont2 h1 {
    font-size: 5rem;
  }

}






@media only screen and (max-width: 650px) {
  .texts {
    font-size: 1rem !important;

  }



  .hbg {
    width: auto;
    height: 80%;
    object-fit: cover;
    bottom: 0;
  }



  .aboutcontainerbg>* {
    width: 99% !important;
  }


  .pinkbag {
    width: 80%;
  }

  .centercolumn h1 {
    font-size: 2rem !important;
  }

  .foot {
    padding: 0.5rem;
  }

  .spider {
    top: -45%;
    width: 100%;
  }

  .spider:hover {
    top: -10%;
  }


  .leftyrighty {
    flex-direction: column;
    gap: 0rem;
  }


  .lefty {
    width: 100% !important;
    padding: 0rem;
  }

  .righty {
    width: 100% !important;
  }

  .lefty h1,
  .lefty div {
    text-shadow: 2px 2px black;
    font-size: 1.3rem !important;
    word-wrap: break-word !important;
  }

  .footimg {
    width: 18rem;
  }

  .stickerLeft,
  .stickerRight {
    width: 7rem;
    height: 7rem;
  }

  .topHeader {
    width: 100%;
  }

  .logo {
    width: 100%;
  }



  .landscape {
    flex-direction: column;
    max-width: 100% !important;
    padding: 1rem;
  }


  .centercolumnimg>* {
    width: 70%;
  }



  .aboutcontainer h1 {
    font-size: 1.5rem !important;
    width: 100%;
    word-break: normal !important;
    word-wrap: break-word !important;
    text-align: center !important;
    line-height: 2rem;
  }

  .funcard p {
    font-size: 0.65rem !important;
  }

  .track>h1 {
    font-size: 1.5rem;
  }


  .buttonsH {
    gap: 0rem !important;
  }

  .logs {

    transform: scale(1) !important;
  }


  .landscape {
    height: 24rem;
  }

  .colcol {
    max-width: none !important;
    min-height: 4rem !important;
  }

  .sticker {
    position: absolute;
    top: -5.5rem;
    left: -2rem;
    height: 14rem;
    z-index: 500;
    transform: rotate(3deg);
  }

  .radabout {
    max-width: none;
  }


  .land {
    gap: 1.5rem !important;
  }

  .lbody {
    min-height: 30vh;
  }


  .ltextcont2 {
    height: 100%;
    padding-inline: 0rem;
  }

  .ltextcont h1,
  .landscape h1 {
    font-size: 2.5rem !important;
  }

  .ltextcont2 h1 {

    word-wrap: break-word;
    word-break: normal;
    text-align: center;
    font-size: 2rem !important;
    text-align: center;
  }

  .landscape p {
    font-weight: normal !important;
    font-size: 1rem !important;
    word-break: normal !important;
    word-wrap: break-word !important;
  }

  .landscape h1 {
    font-size: 1.5rem !important;
    word-break: normal !important;
    word-wrap: break-word !important;
  }


  .calabel {

    font-size: 0.6rem !important;
  }

  .ca {
    font-size: 0.75rem !important;
    word-break: break-all !important;
  }


  .footerAbout {
    font-size: large !important;
    width: 99.5%;
    text-align: justify;
  }


  .about p {
    font-size: 1.3rem !important;
    font-weight: bold;
    width: 18rem;
  }


  .textlogo {
    font-size: 4rem;
  }

  .section {
    min-height: 10vh !important;
  }

  .btnBig {
    padding: 0.8rem !important;
    font-size: 1.2rem !important;
  }



  .land {

    min-height: 100vh !important;
  }

  .container {
    background-size: cover !important;
  }

}

.p,
.h1 {
  word-break: normal !important;
  word-wrap: break-word !important;
}