body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  min-width: 350px;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  padding: 0;
}



code {
  font-family: "Lato", sans-serif;
}

p {
  font-family: "Lato", sans-serif;
  word-break: break-all;
}


* {
  font-family: "Lato", sans-serif;
  padding: none;
  margin: none;
}

input {
  font-family: "Chewy", serif;
  background-color: transparent;
  font-style: italic;
}

a {
  text-decoration: none;
  font-family: "Chewy", serif;
  color: white;
}


button {
  font-family: "Chewy", serif;
  font-style: italic;
}


h1 {
  font-family: "Chewy", serif;
  font-weight: bold;
  font-style: normal;
  text-shadow: 2px 2px black;
}